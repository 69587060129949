import React, {Suspense, useEffect} from "react";

import {useModal} from "../Hooks/modal";
import {useCookies} from "../Hooks/cookies";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import Loading from "../Elements/Loading";
import {pushDataLayerInit} from "../Actions/dataleyer";
const CookiesComponent = React.lazy(() => import('../Cookies'));
const Metas = ({title, description, keys}) => {

    const {modal, closeModal, openModal} = useModal();
    const {funcional, analitica, publicidad} = useCookies();
    const location = useLocation();

    useEffect(() => {
        if (!funcional){
            openModal({modal: 'cookies'})
        }

        if (analitica) {
            //TODO Añadir funcion para agregar los scripts
            pushDataLayerInit()

        }
    }, [analitica, location.pathname]);

    useEffect(() => {
        if (modal.modalOpen) {
            closeModal()
        }
    }, [location.pathname]);

    return (
        <>
            <Helmet>
                {analitica && (
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-30YDD43156"></script>
                )}
                {funcional && (
                    <script></script>
                )}
                {publicidad && (
                    <script></script>
                )}
                <title>{title}</title>
                <meta name="description" content={description}/>
                <meta name="keywords" content={keys}/>
                <meta name="robots" content="index, follow" />
                <meta name="googlebot" content="index, follow" />
            </Helmet>
            {modal.cookies && <Suspense fallback={<Loading/>}><CookiesComponent/></Suspense> }
        </>
    )
}


export default Metas;
import React from "react";

import {
    Col,
    Container,
    Row, UncontrolledAlert
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "../../components/Footers/SimpleFooter";
import Metas from "../../components/Metas/Metas";
import {CookiesProvider} from "../../components/Hooks/cookies";
import {ModalProvider} from "../../components/Hooks/modal";


class TermsConditions extends React.Component {
    render() {

        const isDevelop = () => {
            return process.env.REACT_APP_ENV === 'development';
        }

        return (
            <>
                <ModalProvider>
                    <CookiesProvider>
                        <Metas
                            title={"Términos y condiciones | EnergyCertiificate"}
                            description={"Estos Términos de Servicio (\"Términos\") rigen el uso del sitio web energycertiificate.com\n" +
                                "                                    (\"Sitio\") y los servicios relacionados ofrecidos por EnergyCertiificate (\"Nosotros\", \"nos\" o\n" +
                                "                                    \"nuestro\")."}
                            keys={"certificado, energético, ApiRest, integración, comunidad autónoma, certificados energéticos, eficiencia energética, edificios, consulta, búsqueda, referencia catastral, términos y condiciones, términos, condiciones"}
                        />
                        {isDevelop() && <UncontrolledAlert className={'alert alert-danger'} style={{
                            "position": "fixed",
                            "z-index": "150",
                            "width": "100%"
                        }}><strong>Atención!</strong> Has entrado en una web en entorno de desarrollo, todos los datos
                            son de prueba y desarrollo.</UncontrolledAlert>}
                        <DemoNavbar/>
                        <main ref="main">
                            <section className="section section-lg section-shaped">
                                <div className="shape shape-style-1 shape-default">
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                </div>
                                <Container className="py-lg-md d-flex">
                                    <div className="col px-0">
                                        <Row>
                                            <Col lg="8">
                                                <h1 className="display-3 text-white">
                                                    Términos y condiciones
                                                </h1>
                                            </Col>
                                        </Row>
                                    </div>
                                </Container>
                            </section>
                            <section className="section section-lg">
                                <Container>
                                    <div className="row-grid align-items-center text-justify">
                                        <p>Estos Términos de Servicio ("Términos") rigen el uso del sitio web
                                            energycertiificate.com
                                            ("Sitio") y los servicios relacionados ofrecidos por EnergyCertiificate
                                            ("Nosotros", "nos" o
                                            "nuestro").
                                        </p>
                                        <p>Al acceder y utilizar el Sitio, aceptas estar sujeto a estos Términos. Si no
                                            estás de acuerdo con
                                            alguno de estos términos, no uses el Sitio.
                                        </p>

                                        <h3>Uso del Sitio</h3>
                                        <p>El contenido de este Sitio es solo para tu información general y uso
                                            personal. Nos reservamos el
                                            derecho de modificar, retirar o cambiar cualquier parte del Sitio sin previo
                                            aviso.
                                        </p>

                                        <h3>Propiedad Intelectual</h3>
                                        <p>El Sitio y todo su contenido, características y funcionalidades (incluidos,
                                            entre otros, todos
                                            los diseños, textos, gráficos, imágenes y software) son propiedad de
                                            EnergyCertiificate o de sus
                                            licenciantes y están protegidos por leyes de derechos de autor y otros
                                            derechos de propiedad
                                            intelectual.
                                        </p>

                                        <h3>Enlaces a Terceros</h3>
                                        <p>El Sitio puede contener enlaces a sitios web de terceros que no están
                                            controlados por
                                            EnergyCertiificate. No tenemos control ni asumimos responsabilidad por el
                                            contenido, las
                                            políticas de privacidad o las prácticas de los sitios web de terceros.
                                        </p>

                                        <h3>Limitación de Responsabilidad</h3>
                                        <p>En la medida máxima permitida por la ley aplicable, en ningún caso
                                            EnergyCertiificate, sus
                                            afiliados, directores, empleados o agentes serán responsables por daños
                                            directos, indirectos,
                                            incidentales, especiales, consecuentes o punitivos que surjan de o estén
                                            relacionados con tu uso
                                            del Sitio.
                                        </p>

                                        <h3>Cambios en los Términos</h3>
                                        <p>Nos reservamos el derecho de actualizar o modificar estos Términos en
                                            cualquier momento sin
                                            previo aviso. Cualquier cambio entrará en vigencia inmediatamente después de
                                            su publicación en
                                            el Sitio. Te recomendamos que revises periódicamente los Términos para estar
                                            al tanto de
                                            cualquier cambio.
                                        </p>

                                        <h2>Uso de Stripe</h2>
                                        <h3>Aceptación de los Términos</h3>
                                        <p>Al utilizar los servicios de pago de Stripe en nuestro Sitio, aceptas estar
                                            sujeto a
                                            estos Términos y a la Política de Privacidad de Stripe. Si no estás de
                                            acuerdo con
                                            estos Términos, no utilices los servicios de pago de Stripe en nuestro
                                            Sitio.</p>
                                        <h3>Uso de los Servicios de Pago de Stripe</h3>
                                        <p>Stripe proporciona servicios de procesamiento de pagos en línea que permiten
                                            a los
                                            usuarios realizar transacciones seguras en nuestro Sitio. Al utilizar los
                                            servicios
                                            de pago de Stripe, aceptas cumplir con todas las leyes y regulaciones
                                            aplicables.</p>
                                        <h3>Seguridad de la Información</h3>
                                        <p>Stripe utiliza medidas de seguridad avanzadas para proteger la información
                                            financiera
                                            y personal de los usuarios. Sin embargo, no podemos garantizar la seguridad
                                            absoluta
                                            de la información transmitida a través de Internet.</p>
                                        <h3>Tarifas y Comisiones</h3>
                                        <p>El uso de los servicios de pago de Stripe puede estar sujeto a tarifas y
                                            comisiones
                                            según lo establecido por Stripe. Al realizar transacciones en nuestro Sitio,
                                            aceptas
                                            pagar todas las tarifas y comisiones aplicables.</p>
                                        <h3>Responsabilidad</h3>
                                        <p>Stripe no será responsable de ningún daño directo, indirecto, incidental,
                                            especial o
                                            consecuente que surja del uso de los servicios de pago en nuestro Sitio.</p>
                                        <h3>Cambios en los Términos</h3>
                                        <p>Nos reservamos el derecho de actualizar o modificar estos Términos en
                                            cualquier
                                            momento sin previo aviso. Cualquier cambio entrará en vigencia
                                            inmediatamente
                                            después de su publicación en nuestro Sitio. Te recomendamos que revises
                                            periódicamente estos Términos para estar al tanto de cualquier cambio.</p>
                                        <h3>Contacto</h3>
                                        <p>Si tienes alguna pregunta sobre estos Términos, contáctanos en <a
                                            href="mailto:admin@energycertiificate.com">admin@energycertiificate.com</a>
                                        </p>
                                        <p>Última actualización: 23/02/2024</p>

                                    </div>
                                </Container>
                            </section>
                        </main>
                        <SimpleFooter/>
                    </CookiesProvider>
                </ModalProvider>
            </>);
    }
}

export default TermsConditions;
import React from "react";

import {
    Col,
    Container,
    Row, UncontrolledAlert
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "../../components/Footers/SimpleFooter";
import Metas from "../../components/Metas/Metas";
import {ModalProvider} from "../../components/Hooks/modal";
import {CookiesProvider} from "../../components/Hooks/cookies";


class PolicyPrivacy extends React.Component {
    render() {

        const isDevelop = () => {
            return process.env.REACT_APP_ENV === 'development';
        }

        return (
            <>
                <ModalProvider>
                    <CookiesProvider>
                        <Metas
                            title={"Política de Privacidad | EnergyCertiificate"}
                            description={"En Energy Certiificate, valoramos y respetamos tu privacidad."}
                            keys={"certificado, energético, ApiRest, integración, comunidad autónoma, certificados energéticos, eficiencia energética, edificios, consulta, búsqueda, referencia catastral, Política de Privacidad  "}
                        />
                        {isDevelop() && <UncontrolledAlert className={'alert alert-danger'} style={{
                            "position": "fixed",
                            "z-index": "150",
                            "width": "100%"
                        }}><strong>Atención!</strong> Has entrado en una web en entorno de desarrollo, todos los datos
                            son de prueba y desarrollo.</UncontrolledAlert>}
                        <DemoNavbar/>
                        <main ref="main">
                            <section className="section section-lg section-shaped">
                                <div className="shape shape-style-1 shape-default">
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                </div>
                                <Container className="py-lg-md d-flex">
                                    <div className="col px-0">
                                        <Row>
                                            <Col lg="8">
                                                <h1 className="display-3 text-white">
                                                    Política de Privacidad
                                                </h1>
                                            </Col>
                                        </Row>
                                    </div>
                                </Container>
                            </section>
                            <section className="section section-lg">
                                <Container>
                                    <div className="row-grid align-items-center text-justify">
                                        <p className={'text-break'}>
                                            En Energy Certiificate, valoramos y respetamos tu privacidad. Esta Política
                                            de
                                            Privacidad describe cómo recopilamos, usamos y protegemos la información
                                            personal
                                            que recopilamos de nuestros usuarios.</p>

                                        <h3>Información que Recopilamos</h3>
                                        <ul>
                                            <li><strong>Información de Registro:</strong> Cuando te registras en nuestro
                                                sitio,
                                                podemos recopilar información como tu nombre, dirección de correo
                                                electrónico,
                                                contraseña y otra información de contacto.
                                            </li>
                                            <li><strong>Información de Uso:</strong> Podemos recopilar información sobre
                                                cómo
                                                utilizas nuestro sitio, como las páginas que visitas, el tiempo que
                                                pasas en
                                                nuestro sitio y otras actividades de navegación.
                                            </li>
                                            <li><strong>Información de Pago:</strong> Si realizas una compra a través de
                                                nuestro
                                                sitio, recopilamos información de pago como tu tarjeta de crédito o
                                                detalles de
                                                cuenta de PayPal.
                                            </li>
                                        </ul>

                                        <h3>Uso de la Información</h3>
                                        <p>Utilizamos la información que recopilamos para:</p>
                                        <ul>
                                            <li>Proporcionar y mantener nuestro sitio y servicios.</li>
                                            <li>Personalizar tu experiencia en nuestro sitio y mostrar contenido
                                                relevante.
                                            </li>
                                            <li>Procesar transacciones y proporcionar soporte al cliente.</li>
                                            <li>Mejorar nuestro sitio y servicios.</li>
                                        </ul>

                                        <h3>Protección de la Información</h3>
                                        <p>Tomamos medidas para proteger la información personal de nuestros usuarios
                                            contra el
                                            acceso no autorizado, la alteración, la divulgación o la destrucción.
                                            Utilizamos
                                            medidas de seguridad físicas, electrónicas y procedimentales para proteger
                                            la
                                            información recopilada a través de nuestro sitio.</p>

                                        <h3>Divulgación a Terceros</h3>
                                        <p>No vendemos, intercambiamos ni transferimos tu información personal a
                                            terceros sin tu
                                            consentimiento, excepto en los siguientes casos:</p>
                                        <ul>
                                            <li>Con tu consentimiento explícito.</li>
                                            <li>Cuando sea necesario para cumplir con la ley o proteger nuestros
                                                derechos,
                                                propiedad o seguridad.
                                            </li>
                                        </ul>

                                        <h3>Enlaces a Terceros</h3>
                                        <p>Nuestro sitio puede contener enlaces a sitios web de terceros. No somos
                                            responsables
                                            de las prácticas de privacidad o el contenido de estos sitios web de
                                            terceros. Te
                                            recomendamos que revises las políticas de privacidad de estos sitios antes
                                            de
                                            proporcionarles tu información personal.</p>

                                        <h3>Cambios en esta Política de Privacidad</h3>
                                        <p>Nos reservamos el derecho de actualizar o modificar esta Política de
                                            Privacidad en
                                            cualquier momento. Te notificaremos cualquier cambio publicando la nueva
                                            Política de
                                            Privacidad en esta página. Te recomendamos que revises esta Política de
                                            Privacidad
                                            periódicamente para estar al tanto de cualquier cambio.</p>

                                        <h3>Contacto</h3>
                                        <p>Si tienes alguna pregunta sobre esta Política de Privacidad, contáctanos
                                            en <a
                                                href="mailto:info@energycertiificate.com">info@energycertiificate.com</a>
                                        </p>
                                        <p>Última actualización: 23/02/2024</p>
                                    </div>
                                </Container>
                            </section>
                        </main>
                        <SimpleFooter/>
                    </CookiesProvider>
                </ModalProvider>
            </>);
    }
}

export default PolicyPrivacy;
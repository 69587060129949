import React from "react";

import {
    Col,
    Container,
    Row, UncontrolledAlert
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "../../components/Footers/SimpleFooter";
import QuestionsFaqs from "../../components/Cards/QuestionsFaqs";
import Metas from "../../components/Metas/Metas";
import {CookiesProvider} from "../../components/Hooks/cookies";
import {ModalProvider} from "../../components/Hooks/modal";


class Faqs extends React.Component {

    render() {
        const isDevelop = () => {
            return process.env.REACT_APP_ENV === 'development';
        }

        return (
            <>
                <ModalProvider>
                    <CookiesProvider>
                        <Metas
                            title={"FAQS - Preguntas frequentes | EnergyCertiificate"}
                            description={"En esta sección resolvemos tus dudas sobre el ApiRest de certificados energeticos"}
                            keys={"certificado, energético, ApiRest, integración, comunidad autónoma, certificados energéticos, eficiencia energética, edificios, consulta, búsqueda, referencia catastral, faqs, preguntas frecuentes, preguntas"}
                        />
                        {isDevelop() && <UncontrolledAlert className={'alert alert-danger'} style={{
                            "position": "fixed",
                            "z-index": "150",
                            "width": "100%"
                        }}><strong>Atención!</strong> Has entrado en una web en entorno de desarrollo, todos los datos
                            son de prueba y desarrollo.</UncontrolledAlert>}
                        <DemoNavbar/>
                        <main ref="main">
                            <div className="position-relative">
                                <section className="section section-lg section-shaped ">
                                    <div className="shape shape-style-1 shape-default">
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                    </div>
                                    <Container className="py-lg-md d-flex">
                                        <div className="col px-0">
                                            <Row>
                                                <Col lg="8">
                                                    <h1 className="display-3 text-white">
                                                        Preguntas frecuentes
                                                    </h1>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Container>
                                </section>
                                <section className="section section-lg pt-lg-0 mt-5">
                                    <Container>
                                        <Row className="justify-content-center">
                                            <QuestionsFaqs></QuestionsFaqs>
                                        </Row>
                                    </Container>
                                </section>
                            </div>
                        </main>
                        <SimpleFooter/>
                    </CookiesProvider>
                </ModalProvider>
            </>);
    }
}

export default Faqs;
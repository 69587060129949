import isSSR from "../Config/isSSR";


const dataLayer = window.dataLayer || []
function gtag() { dataLayer.push(arguments)}

export const pushDataLayerInit = (path) => {
    if(!isSSR() && process.env.REACT_APP_ENV !== 'development') {
        gtag('js', new Date())
        gtag('config', 'G-30YDD43156', {
            page_path: path
        })
    }else {
        console.log('No analitics');
    }
}
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Landing from "views/examples/Landing.js";
import PolicyCookies from "./views/examples/PolicyCookies";
import PolicyPrivacy from "./views/examples/PolicyPrivacy";
import Prices from "./views/examples/Prices";
import Faqs from "./views/examples/Faqs";
import TermsConditions from "./views/examples/TermsConditions";
import Documentation from "./views/examples/Documentation";
import NotFoundPage from "./views/examples/NotFoundPage";
import Sitemap from "./views/examples/Sitemap";


const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" exact element={<Landing />} />
      <Route path="/politica-de-cookies" exact element={<PolicyCookies />} />
      <Route path="/politica-de-privacidad" exact element={<PolicyPrivacy />} />
      <Route path="/suscripciones" exact element={<Prices />} />
      <Route path="/faqs" exact element={<Faqs />} />
      <Route path="/terminos-y-condiciones" exact element={<TermsConditions />} />
      <Route path="/documentacion" exact element={<Documentation />} />
      <Route path="/sitemap.xml" exact element={<Sitemap />} />
      <Route path="*" exact element={<NotFoundPage />} />
    </Routes>
  </BrowserRouter>
);

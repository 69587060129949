import React from "react";

import {
    Button,
    Card, CardBody, CardFooter,
    Col,
    Container,
    Row, UncontrolledAlert
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "../../components/Footers/SimpleFooter";
import Contact from "../../components/Forms/Contact";
import Metas from "../../components/Metas/Metas";
import {CookiesProvider} from "../../components/Hooks/cookies";
import {ModalProvider} from "../../components/Hooks/modal";


class Prices extends React.Component {

    urlCreateBack = process.env.REACT_APP_API_URL_SERVER_CREATE_ACCOUNT_BACK;

    render() {
        const isDevelop = () => {
            return process.env.REACT_APP_ENV === 'development';
        }

        return (
            <>
                <ModalProvider>
                    <CookiesProvider>
                        <Metas
                            title={"Suscripciones | EnergyCertiificate"}
                            description={"Aqui puedes consultar el tipo de suscripciones que tenemos."}
                            keys={"certificado, energetico, ApiRest, integración, comunidad autonoma, inmuebles, inmobiliarias, suscripción, planes, precios, consulta"}
                        />
                        {isDevelop() && <UncontrolledAlert  className={'alert alert-danger'} style={{"position": "fixed", "z-index":"150", "width": "100%"}}><strong>Atención!</strong> Has entrado en una web en entorno de desarrollo, todos los datos son de prueba y desarrollo.</UncontrolledAlert >}
                        <DemoNavbar/>
                        <main ref="main">
                            <div className="position-relative">
                                <section className="section section-lg section-shaped ">
                                    <div className="shape shape-style-1 shape-default">
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                    </div>
                                    <Container className="py-lg-md d-flex">
                                        <div className="col px-0">
                                            <Row>
                                                <Col lg="8">
                                                    <h1 className="display-3 text-white">
                                                        Suscripciones
                                                    </h1>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Container>
                                </section>
                                <section className="section section-lg pt-lg-0 mt-5">
                                    <Container>
                                        <Row className="justify-content-center">
                                            <Col lg="12" className="mb-5">
                                                <h2>Consulta tu plan de suscripción</h2>
                                            </Col>
                                            <Col lg="12">
                                                <Row className="row-grid">
                                                    <Col lg="4">
                                                        <Card className="card-lift--hover shadow border-0">
                                                            <CardBody className="py-5">
                                                                <div
                                                                    className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                                                    <i className="ni ni-single-02"/>
                                                                </div>
                                                                <h6 className="text-primary text-uppercase">
                                                                    Basic
                                                                </h6>
                                                                <p className="mt-3 text-justify">
                                                                    Paquete básico para empresas pequeñas y con pocas consultas.
                                                                </p>
                                                                <ul>
                                                                    <li>Acesso a la ApiRest</li>
                                                                    <li>1 Usuario</li>
                                                                    <li>Limite de 200 peticiones diarias</li>
                                                                </ul>
                                                            </CardBody>
                                                            <CardFooter><p>Por solo <strong>9,99€</strong> al mes</p></CardFooter>
                                                        </Card>
                                                    </Col>
                                                    <Col lg="4">
                                                        <Card className="card-lift--hover shadow border-0">
                                                            <CardBody className="py-5">
                                                                <div
                                                                    className="icon icon-shape icon-shape-success rounded-circle mb-4">
                                                                    <i className="ni ni-spaceship"/>
                                                                </div>
                                                                <h6 className="text-success text-uppercase">
                                                                    Premium
                                                                </h6>
                                                                <p className="mt-3 text-justify">
                                                                    Paquete completo para empresas medianas.
                                                                </p>
                                                                <ul>
                                                                    <li>Acesso a la ApiRest</li>
                                                                    <li>1 Usuario</li>
                                                                    <li>Limite de 600 peticiones diarias</li>
                                                                </ul>
                                                            </CardBody>
                                                            <CardFooter><p>Por solo <strong>14,99€</strong> al mes</p></CardFooter>
                                                        </Card>
                                                    </Col>
                                                    <Col lg="4">
                                                        <Card className="card-lift--hover shadow border-0">
                                                            <CardBody className="py-5">
                                                                <div
                                                                    className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                                                                    <i className="ni ni-briefcase-24"/>
                                                                </div>
                                                                <h6 className="text-warning text-uppercase">
                                                                    Enterprice
                                                                </h6>
                                                                <p className="mt-3 text-justify">
                                                                    Pensado para empresas grandes, con varios proyectos.
                                                                </p>
                                                                <ul>
                                                                    <li>Acesso a la ApiRest</li>
                                                                    <li>4 Usuario</li>
                                                                    <li>Limite de 2000 peticiones diarias</li>
                                                                </ul>
                                                            </CardBody>
                                                            <CardFooter><p>Por solo <strong>29,99€</strong> al mes</p></CardFooter>
                                                        </Card>
                                                    </Col>
                                                    <Col lg="12" className="mt-5">
                                                        <p>Todos los paquetes son ampliables mediante extras. Podrás añadir mas usuarios o mas peticiones a tu paquete de suscripción.</p>
                                                    </Col>
                                                    <Col lg="12" className="mt-5">
                                                        <h4>¿Te gusta alguna de las suscripciones?</h4>
                                                        <p>¡No lo pienses más y crea tu cuenta ya!</p>
                                                        <Button
                                                            className="btn-neutral btn-icon"
                                                            color="default"
                                                            href={this.urlCreateBack}>
                                                            Crear cuenta
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </section>
                                <section className="section section-lg pt-lg-0 section-contact-us">
                                    <Container>
                                        <Row className="justify-content-center">
                                            <Col lg="12">
                                                <Card className="bg-gradient-secondary shadow">
                                                    <CardBody className="p-lg-5">
                                                        <h4 className="mb-1">¿Tienes dudas?</h4>
                                                        <p className="mt-0">
                                                            Contacta con nosotros y te informaremos
                                                        </p>
                                                        <Contact/>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </section>
                            </div>
                        </main>
                        <SimpleFooter/>
                    </CookiesProvider>
                </ModalProvider>
            </>);
    }
}

export default Prices;